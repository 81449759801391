// import FontAwesome 5 Pro (SVG Core)
import './lib/fontawesome.js';

// import navbar collapse toggle js
import 'bootstrap/js/dist/collapse';

// Import SCSS
import '../styles/index.scss';

// Add js-cookie: https://github.com/js-cookie/js-cookie
import Cookie from 'js-cookie';


// Add Lightbox2: https://lokeshdhakar.com/projects/lightbox2/
import 'lightbox2/dist/css/lightbox.css';
window.lightbox = require('lightbox2');

// Your custom JS here...