/** Include FontAwesome Pro 5 **/
import {library, dom, config} from '@fortawesome/fontawesome-svg-core';

import {fal} from '@fortawesome/pro-light-svg-icons';
import {far} from '@fortawesome/pro-regular-svg-icons';
import {fas} from '@fortawesome/pro-solid-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';


// you can also import single icons, e.g.:
// import { faCamera } from '@fortawesome/pro-solid-svg-icons'
import { faLockAlt } from '@fortawesome/pro-regular-svg-icons';
import {faLockOpenAlt} from '@fortawesome/pro-regular-svg-icons';
import { faArrowRight, faEye } from '@fortawesome/pro-solid-svg-icons';
import {faLock} from '@fortawesome/pro-regular-svg-icons';
import {faLockOpen} from '@fortawesome/pro-regular-svg-icons';
import {faUserAlt} from '@fortawesome/pro-regular-svg-icons';
import {faCircle} from '@fortawesome/pro-solid-svg-icons';
import {faArrowCircleDown} from '@fortawesome/pro-regular-svg-icons';
import {faFileAlt} from '@fortawesome/pro-light-svg-icons';
import {faBars} from '@fortawesome/pro-regular-svg-icons';



// add the styles/icons to the library, consider removing unnecessary styles
library.add(faLockAlt,faLockOpenAlt, faArrowRight, faEye, faLock, faLockOpen, faUserAlt,faCircle, faArrowCircleDown, faFileAlt, faBars);
//library.add(fas, far, fab);

// activate pseudo element support if needed
config.searchPseudoElements = true;

// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.
dom.watch();